import axios from "axios";

export default {
  /**
   * Get route between two points
   * @param {number} startLon - Starting longitude
   * @param {number} startLat - Starting latitude
   * @param {number} endLon - Ending longitude
   * @param {number} endLat - Ending latitude
   * @returns {Promise} - Promise with route data
   */
  getRoute(startLon, startLat, endLon, endLat) {
    console.log(`Calculating route from ${startLat},${startLon} to ${endLat},${endLon}`);
    
    // Create the positions string for the routing API
    const positions = `${startLon},${startLat};${endLon},${endLat}`;
    
    // Create a URL to our PHP proxy
    const proxyUrl = `/api/routing.php?server=txosmr&positions=${positions}`;
    
    // Set up a timeout of 3 seconds for the API call
    const routingApiPromise = axios.get(proxyUrl, {
      timeout: 3000 // 3 seconds timeout
    });
    
    // Return a promise that races between the API call and a timeout
    return new Promise((resolve) => {
      let resolved = false;
      
      // Try the API call
      routingApiPromise
        .then(response => {
          if (!resolved) {
            resolved = true;
            resolve(response);
          }
        })
        .catch(error => {
          if (!resolved) {
            resolved = true;
            console.warn("Routing API failed, using estimation:", error.message);
            resolve(this.calculateRouteEstimate(startLon, startLat, endLon, endLat));
          }
        });
      
      // Set an additional safety timeout - if the axios request doesn't resolve or reject within 3.5s
      setTimeout(() => {
        if (!resolved) {
          resolved = true;
          console.warn("Routing API timed out, using estimation");
          resolve(this.calculateRouteEstimate(startLon, startLat, endLon, endLat));
        }
      }, 3500);
    });
  },

  /**
   * Calculate an estimated route using geographic formulas
   * This provides a reasonable approximation when the routing API isn't available
   */
  calculateRouteEstimate(startLon, startLat, endLon, endLat) {
    // Calculate distance using Haversine formula
    const R = 6371; // Earth's radius in km
    const dLat = (endLat - startLat) * Math.PI / 180;
    const dLon = (endLon - startLon) * Math.PI / 180;
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(startLat * Math.PI / 180) * Math.cos(endLat * Math.PI / 180) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
    let distance = R * c * 1000; // Distance in meters
    
    // Apply a "real-world route factor" to account for non-direct roads
    // Typically roads are 20-30% longer than straight-line distance
    distance = distance * 1.3;
    
    // Calculate duration based on typical urban speeds
    // - For short distances (<2km): 30km/h average (8.3 m/s)
    // - For medium distances (2-10km): 45km/h average (12.5 m/s)
    // - For longer distances (>10km): 65km/h average (18 m/s)
    let speed;
    if (distance < 2000) {
      speed = 8.3; // meters per second
    } else if (distance < 10000) {
      speed = 12.5; // meters per second
    } else {
      speed = 18; // meters per second
    }
    
    const duration = distance / speed;
    
    // Return data formatted like the expected API response
    return {
      data: {
        routes: [{
          distance: Math.round(distance), // Round to nearest meter
          duration: Math.round(duration), // Round to nearest second
          geometry: null,
          legs: [],
          weight: Math.round(distance),
          weight_name: "estimated"
        }],
        waypoints: [
          { hint: "", distance: 0, name: "", location: [startLon, startLat] },
          { hint: "", distance: 0, name: "", location: [endLon, endLat] }
        ],
        code: "Ok",
        message: "Using distance estimation (direct calculation)"
      }
    };
  }
};
